*{
    margin: 0;
    padding:0;
    max-width: 100%;
    box-sizing: border-box;
}

body{
    font-family: 'Inter', sans-serif; 
}
.header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#spacer{
    width: 80px;
}

.header h1{
    font-style: normal;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
}

.div1{
    display: flex; 
    padding-left: 90px;
    padding-top: 60px;
    margin-bottom: 20px
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
}

.content a, .start-button, .bmv-button{
    text-decoration: none;
    margin: 30px 0;
    background-color: #3C64B1;
    color: white;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    border-radius: 18px;
    padding: 12px 18px;
}

#index-start{
    font-size: 23px;
    padding: 12px 28px;
}

.start-button-wrapper, .bmv-button-wrapper{
    margin-top: 30px;
    text-align: center;
}
.content-text{
    padding: 40px 0;
    background-color: #EDF3FF;
    box-shadow: 5px 5px #CDCDFF;
}

.content-text h2{
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    letter-spacing: 0.2px;
    color: #18191F;
}

.content-text p{
    padding: 20px 30px;
    text-align: justify;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 56px;
    color: #18191F;
}

.content-images{
    margin-top: 10px;
    padding-left: 50px;
}

.separator{
    height: 4px;
    background: linear-gradient(to right, #002D5D 0%,#002D5D 33%,#5578BB 33%,#5578BB 66%,#97AEDA 66%,#97AEDA 100%);
}

.processflow h2{
    margin-right: -20px;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    text-align: center;
    color: #18191F;
    margin-top: 40px;
}

.process-flow-steps{
    display:flex;
    margin : 40px ;
    justify-content: space-evenly;
    margin-bottom: 80px;
}

.div2{
    margin : 40px;
    display: flex;
    justify-content: center;
}

.papers-links div{
    position: relative;
    display: block;
}

/*links of related papers*/
#papers-links-container{
    width: 1400px;
}

.papers-links{
    margin-top: 20px;
    text-align: center;
   
}

.papers-links a{
    text-decoration: none;
    display: inline-block;
    color: #000;
    font-size: 18px;
    padding: 10px 20px;
    margin: 10px 0px;
}
 
.papers-links a::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #000;
    transition: width .3s;
}

.papers-links a:hover::after{
    width: 100%;
    transition: width .3s;
} 

#bpmn-logo {
    margin-left: 60px;
    height: 333px;
}

#maude-logo {
    margin-top: 10px;
    height: 131px;
    margin-left: 30px;
}

.diagram{
    background-color: #EDF3FF;
    box-shadow: 5px 5px #CDCDFF;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    width: 80%;
}
.diagram p{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.canvas-title{
    padding: 20px 30px;
    font-size: 20px !important;
}
.result-container{
    padding-top: 20px;
}
.result-table{
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0px;
    margin: auto;
}

.result-table td, .result-table th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 50px;
    max-width: 80%; 
    background-color: white;
}

.result-table tr:last-child{
    background-color: #CDCDFF;
}
.result-table tr:hover {background-color: #f2f2f2;}

.result-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #3C64B1;
  color: white;
}

#diagram-visualization{
    height: 600px;
}

#results-visualization{
    min-height: 200px;
}

.separator-bmv{
    height: 2px;
    background-color: #9db1d7;
}
.separator-form{
    height: 1px;
    background-color: #3C64B1;
    width: 100%;
}
.home{
    text-decoration: none;
    margin: 0 20px;
    background-color: #3C64B1;
    color: white;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    border-radius: 18px;
    padding: 12px 18px;
}

.diagram-container{
    display:flex;
    justify-content: space-between;
}

#canvas{
    height: 600px;
    width: 80%;
}

#property-panel{
    background-color: #EDF3FF;
    height: 600px;
}

.diagram-buttons{
    display: flex;
    margin: 0 150px;
    justify-content: space-between;
}


@media only screen and (max-width: 1100px) {
    .diagram-buttons{
        display: flex;
        flex-direction: column;
    }
  }

@media only screen and (max-width: 558px) {
    .diagram-buttons{
        display: flex;
        margin: 0px;
        flex-direction: column;
    }
  }

.diagram-buttons a {
    text-decoration: none;
}

.form-container{
    display: none;
    position: absolute;
    z-index: 1;
    left: 50%;  
    top: 50%; 
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 50%;
    width: 50%; 
}

.form-container-header{
    cursor: move;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.allocation-input-container, .allocation-input-container div, .interval-wrapper div{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.inner-container{
    padding:15px 0px;
}

.interval-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
}

#smaller-container{
    width: 40%;
} 

.close-buttom{
    position: absolute;
    top:12px; 
    right: 25px;
    text-decoration: none;
    font-size: 30px;
    color: #000;
}
.ltl-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #fefefe;
    margin: 5% auto 15% auto; 
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
}

.ltl-form-label{
    cursor: move;
    font-size: 19px;
    line-height: 24px;
    padding: 10px;
    padding-top: 20px;
    font-style: normal;
    font-weight: bold;
}

#ltl-formula{
    background: rgba(123, 151, 206, 0.06);
    border: 1px solid #3C64B1;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    height: 50%;
    padding: 20px 0px;
    width: 80%;
    margin-top: 20px;
}

 .inner-container input, .interval-wrapper div input, .form-input {
    background: rgba(123, 151, 206, 0.06);
    border: 1px solid #3C64B1;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    width: 80%;
    text-align: center;
}

.form-button{
    background: #3C64B1;
    border-radius: 18px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    border-style:solid;
    padding: 12px 18px;
    margin:10px 0px;
}

/* helper pop ups  */

.popup-wrapper{
    background-color: #5578BB;
    display: block;
    position: absolute;
    right: 25px;
}

.button-container{
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #555;
} 
/* .popup-wrapper a{ */
.button-container a{
    position: relative;
    left: 312%;
    text-align: center;
    text-decoration: none;
    font-size: 25px;
    color: #000; 
}

.smaller-helper{
    margin-left : -8px;
}

#stop-helper{
    left: 180%;
}
/*help pop up*/
.help-popup{
    visibility: hidden;
    /* width and height doesnt work because of display:flex of parents containers */
    min-width: 910px ;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
    border-radius: 16px;    
    background-color: #3C64B1;
    color: #fff;
    padding:15px;
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    left : 50%;
    margin-left: -455px;
}

/* Popup arrow */
.help-popup::after {
    z-index: 99;
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #3C64B1;
}

.sidenav-help-popup{
    visibility: hidden;
    max-width: 350px ;
    min-height: 122px;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
    border-radius: 16px;    
    background-color: #111; /* Black*/
    color: #f1f1f1;
    padding:15px;
    position: fixed;
    z-index: 2;
    left : 265px;   
}

.sidenav-help-popup p{
    margin-top:10px;
}

#sidenav-helper-popup{
    bottom: 0px;
}

#time-helper-popup{
    top: 60px;
}
#par-helper-popup{
    top: 110px;
}
#mc-helper-popup{
    top: 150px;
} 

#liv-helper-popup{
    top: 200px;
}

#ran-helper-popup{
    top: 245px;
}

#ral-helper-popup{
    top: 290px;
}

/* Popup arrow */
.sidenav-help-popup::after {
    z-index: 99;
    content: "";
    position: absolute;
    top: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #111;
}

/* Toggle this class - hide and show the popup */
.show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;} 
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}

.disableClick{
    pointer-events: none;
}

/* side menu */
.sidenav {
    height: 100%; 
    width: 0; 
    position: fixed; 
    z-index: 1; 
    top: 0; 
    left: 0;
    background-color: #111; 
    overflow-x: hidden; 
    padding-top: 60px; 
    transition: 0.5s; 
  }
  
/* menu links */
.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.5s;
}
  
.sidenav a:hover{
    color: #f1f1f1;
}
  
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.sidenav div{
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: #818181;;
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 25px;
    left: 25px;
    transition: 0.5s;
}

.sidenav div:hover{
    border-color: #f1f1f1;
}

#side-nav-helper{
    position: absolute;
    left: 7px;
    padding: 0px;
}   

#main {
    transition: margin-left 0.5s;
    /*padding: 20px;*/
}
  
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.clock-container{
    background: rgba(123, 151, 206, 0.06);
    border: 1px solid #3C64B1;
    box-sizing: border-box;
    border-radius: 8px;
    height: 50%;
    padding: 20px 0px;
    width: 50%;
    text-align: center;
}

#hms{
    height:68px;
    font-family: sans-serif;
    font-size:50px;
    color:#000;
}


